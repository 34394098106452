import * as React from 'react'
import Layout from '../components/layout'
import { StaticQuery, graphql, Link } from "gatsby"

// const queryM = () => {

// };

// export const query = graphql`
// query MyQuery {
//    allFeedCsv {
//       nodes {
//         id
//         productDescription
//         productName
//         productPrice
//       }
//     }
//   }`;

const  AboutPage = () => {

  return (
    <StaticQuery
query= { graphql`
query MyQuery {
  allFeedCsv {
    nodes {
      id
      productDescription
      productName
      productPrice
    }
  }
}`}
render={data =>  (
    <main>
      <title>About Me</title>
      <Layout pageTitle="About Me">
      <table>
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Product Description</th>
            <th>Product Price</th>
          </tr>
        </thead>
        <tbody>
          {data.allFeedCsv.nodes.map(node => (
            <tr key={node.id}>
              <th>{node.productName}</th>
              <th>{node.productDescription}</th>
              <th>{node.productPrice}</th>
            </tr>
          ))}
        </tbody>
      </table>
      </Layout>
    </main>
  )}/>
  )
}
export default AboutPage;